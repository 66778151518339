<template>
    <div data-app>
        <v-card>
            <v-card-title>
                <v-row>
                    <v-col cols="12" sm="6" md="6" style="color: #e33354" class="ml-4">
                         <h3>{{ cardTitle }}</h3>
                    </v-col>
                    <v-spacer></v-spacer>
                    <v-col cols="12" sm="3" md="3">
                        <v-text-field
                          v-model="search"
                          append-icon="search"
                          :label="$t('FORMS.search')"
                          single-line
                          hide-details
                        ></v-text-field>
                    </v-col>
                </v-row>
            </v-card-title>
            <v-card-text>
                <v-data-table
                    :headers="headers"
                    :items="bannerClientCollection"
                    :search="search"
                    :sort-by="['id']"
                    sort-desc="true"
                    :loading="loadingTable"
                    @click:row="handleClickItem"
                >
                    <template v-slot:top>
                        <v-toolbar flat>
                            <BannerClientModalForm
                              :modalData="modalData"
                              :permissions="permissions"
                              :endPoint="endPoint"
                              @closeModalForm="handleCloseModalForm"
                              @saveModalForm="handleSaveModalForm"
                              @new="handleNew"
                            ></BannerClientModalForm>
                            <v-spacer></v-spacer>
                            <DeleteModalDialog
                              :dialogDelete="dialogDelete"
                              @closeDelete="handleCloseDelete"
                              @deleteItemConfirm="handleDeleteItemConfirm"
                            >
                            </DeleteModalDialog>
                        </v-toolbar>
                    </template>    
                    
                    <template v-slot:item.actions="{ item }">
                        <v-icon
                          color="primary"
                          v-if="permissionCan('delete') && item.deletable"
                          small
                          @click="deleteItem(item)"
                        >
                          mdi-delete
                        </v-icon>
                    </template>
                    
                </v-data-table>
            </v-card-text>
        </v-card>
    </div>
</template>

<script>
import { mapGetters, mapActions } from "vuex";
import SiteService from "@/core/services/site.service.js";
import { listModelsMixins } from "@/view/mixins/listModelsMixins.js";
import BannerClientModalForm, { initialFormData } from "./BannerClientModalForm";
import DeleteModalDialog from "@/view/components/DeleteModalDialog";
export const PERMISSION_TO = "pages.bannerClient.";
export default {
    name: "BannerClients",
    components: { BannerClientModalForm, DeleteModalDialog },
    mixins: [listModelsMixins],
    data() {
      return {
        cardTitle: this.$t("MENU.bannerClients"),
        search: "",
        routePath: "/cms/banners/bannerClients/",
        permissionTo: PERMISSION_TO,
        permissions: JSON.parse(localStorage.permissions),
        headers: [
          {
            text: this.$t("TABLE.name"),
            value: "name",
          },
          { text: "Actions", value: "actions", sortable: false, width: "100px" },
        ],

        modalData: {
            dialog: false,
            editedId: null,
        },
        editedItem: Object.assign({}, initialFormData()),
        dialogDelete: false,
        };
    },

    computed: {
      ...mapGetters(["bannerClientCollection"]),

      endPoint() {
        return "pages/admin/" + SiteService.getActiveSiteId() + "/bannerClient/";
      },
    },
    
    watch: {
        dialogDelete(val) {
          val || this.handleCloseDelete();
        },
        "$route.params.id": {
          handler: function (id) {
            if (id) {
              this.editItem(id);
            }
          },
          deep: true,
          immediate: true,
        },
    },

   
    methods: {
        ...mapActions(["fetchBannerClient"]),
        fetchModel() {
              return this.fetchBannerClient();
        },
    },

    mounted() {
      this.loadingTable = true;
      this.fetchModel().then(() => (this.loadingTable = false));
    },
};
</script>